.bannerImage{
    height: 200px;
    position: relative;
    object-fit: none;
    padding: 0px;
    border-radius: 0.75rem 0.75rem 0px 0px;
}
.profileImage{
    z-index: 1;
    position: absolute;
    max-height: 20vh;
    width: auto;
    margin-left: 1.5vw;
    border: 2px solid #000000;
    padding: 0;
    border-radius: 0.75rem;
}

.bannerText{
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.6); /* Black w/opacity/see-through */
    color: white;
    position: absolute;
    max-height: 20vh;
    left: 50%;
    z-index: 2;
    width: fit-content;
    padding: 2.5rem;
    border-radius: 0.75rem;
    transform: translate(-50%, 0%);
}

.bannerText > h1{
    font-size: 2rem;
    font-weight: bold;
}

.bannerText > h3{
    font-size: 1rem;
    font-weight: lighter;
}
@media screen and (max-width: 399px) {
    .bannerText{
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0, 0.6); /* Black w/opacity/see-through */
        color: white;
        position: absolute;
        left: 50%;
        transform: translate(-15%, 0%);
        z-index: 1;
        width: fit-content;
        padding: 2vh;
        text-align: center;
        border-radius: 0.75rem;
    }
    .bannerImage{
        height: 20vh;
        position: relative;
        object-fit: none;
        padding: 0px;
        border-radius: 0.75rem 0.75rem 0px 0px;
    }
    .profileImage{
        z-index: 1;
        position: absolute;
        max-height: 12.5vh;
        width: auto;
        margin-left: 2.5vh;
        border: 2px solid #000000;
        padding: 0;
        border-radius: 0.75rem;
    }
    .bannerText > h1{
        font-size: 1rem;
        font-weight: bold;
    }
    
    .bannerText > h3{
        font-size: .75rem;
        font-weight: lighter;
    }
}
.accordion {
    max-width: 600px;    
} 
.accordion-title {
    cursor: pointer;
    background-color: white;
    font-size: 2vw;
}
.accordion-title:hover {
    background-color: rgb(231, 231, 231);
}
.accordion-title,
.accordion-content {
    padding: 1rem;
}
.accordion-content {
    background-color: rgb(255, 255, 255);
}
.accordion-hr {
    width: 30%;
    margin: auto;
    
}

.appCard{
    opacity: 0.5;
    padding-top: 10px;
}
.appCard-p{
    font-size: 24pt;
}
.appCard-img{
    width: 75px;
}
.appCard-p:hover{
}
.appCard:hover{
    background-color: rgb(214, 214, 214);
    border-radius: 5px;
    opacity: 1;
}

.projectmodal-title{
    font-size: 20pt;
    font-weight: bold;
}
.projectmodal-subtitle{
    font-size: 10pt;
    font-style: italic;
}